import * as React from 'react';
import type { FontWeightsEnum, KosmosFontSize } from '@mentimeter/ragnar-dsc';
import { Text } from '../text';
import { TooltipOld } from '../tooltip-legacy';
import type { PlacementT } from '../placement';

export interface TextEllipsisT {
  id: string;
  text: string;
  tooltipDescription: string;
  color?: string;
  fontWeight?: FontWeightsEnum;
  fontSize?: KosmosFontSize;
  placement?: PlacementT;
  className?: string | undefined;
}
/**
 * This should only be used in places where a text can be to long and needs to be ellipsed. If ellipsis is used, a tooltip will also be shown.
 * To make ellipsis work the parent component of TextEllipsis needs to have a width property.
 */
export const TextEllipsis = (props: TextEllipsisT) => {
  const {
    text,
    tooltipDescription,
    id,
    color,
    fontWeight,
    fontSize,
    placement = 'bottom',
    className,
  } = props;
  const [isEllipsisActive, setIsEllipsisActive] = React.useState(false);
  const spanRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    const span = spanRef.current;
    if (span) {
      setIsEllipsisActive(span.offsetWidth < span.scrollWidth);
    }
  }, [text]);

  return (
    <>
      <Text
        ref={spanRef}
        id={id}
        className={className}
        truncate
        {...(color && { color })}
        {...(fontWeight && { fontWeight })}
        {...(fontSize && { fontSize })}
      >
        {text}
      </Text>

      {isEllipsisActive && (
        <TooltipOld
          description={tooltipDescription}
          referenceId={id}
          placement={placement}
          delay={0}
        />
      )}
    </>
  );
};
